import type { LayoutRecord } from "~/graphql/datocms/generated";

export async function useLayout() {
  const nuxtApp = useNuxtApp();
  const { locale } = useI18n();

  const { data } = await useFetch<LayoutRecord>("/api/cms/layout", {
    query: { locale: locale },
    getCachedData: key => nuxtApp.payload.data[key] || nuxtApp.static.data[key],
  });

  return data;
}
