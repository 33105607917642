<script lang="ts" setup>
const { loggedIn, user, clear } = useUserSession();
const { t } = useI18n();

const accountItems = [
  [{
    label: t("auth.label.sign-out"),
    icon: "i-ic-round-log-out",
    click: async () => {
      await clear();
      await navigateTo("/", { external: true });
    },
  }],
];
</script>

<template>
  <NuxtLinkLocale v-if="!loggedIn" to="/auth/login">
    {{ $t("auth.label.sign-in") }}
  </NuxtLinkLocale>

  <NuxtLinkLocale v-if="!loggedIn" to="/auth/register">
    {{ $t("auth.label.create-account") }}
  </NuxtLinkLocale>

  <UDropdown
    v-if="loggedIn"
    mode="click"
    class="z-30"
    :items="accountItems"
    :ui="{ base: '!mt-md' }"
    :popper="{ placement: 'bottom-start' }"
  >
    <div class="flex items-center gap-xs">
      <UIcon name="i-ic-round-person-outline" class="size-6" />
      <span>{{ user?.name ?? user?.email ?? "[User]" }}</span>
      <UIcon name="i-ic-round-keyboard-arrow-down" class="size-6" />
    </div>

    <template #item="{ item }">
      <span class="truncate text-[1rem] font-medium text-blue hover:text-blue-700">{{ item.label }}</span>
      <UIcon :name="item.icon" class="ms-auto size-4 shrink-0 text-blue hover:text-blue-700" />
    </template>
  </UDropdown>
</template>

<style scoped></style>
