<script lang="ts" setup>
import type { LayoutModelMainNavigationField } from "~/graphql/datocms/generated";

defineProps<{ navItems: LayoutModelMainNavigationField[] }>();

const { loggedIn } = useUserSession();
</script>

<template>
  <UContainer class="py-lg">
    <div class="flex items-center justify-between gap-xl font-bold text-blue">
      <div class="flex gap-md">
        <HeaderMobileMenu v-if="loggedIn" :nav-items="navItems" />
        <NuxtLinkLocale to="/">
          <NuxtImg preload src="/logos/vanuxeem.svg" alt="Vanuxeem Logo" title="Vanuxeem" class="h-[36px]" />
        </NuxtLinkLocale>
      </div>
      <div class="flex gap-lg">
        <HeaderMobileAccountControls />
        <HeaderLocaleSwitcher :is-mobile="true" />
        <CartMini v-if="loggedIn" :is-mobile="true" />
      </div>
    </div>
  </UContainer>
</template>

<style scoped></style>
