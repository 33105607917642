<script lang="ts" setup>
defineProps<{ isMobile?: boolean }>();

const cart = useCart();
const { refreshCart } = await useCartLoader({ immediate: false });

async function fetchData() {
  if (cart.value.cartId) {
    return;
  }
  refreshCart();
}

onMounted(async () => {
  // initial fetch, will load the cart into the store
  fetchData();
});
</script>

<template>
  <NuxtLinkLocale to="/checkout" class="relative">
    <UBadge
      class="relative -top-md z-10 justify-center rounded-full bg-blue px-md text-sm font-semibold text-white"
      :class="isMobile
        ? 'h-2xl py-0 border-white box-content border-2 ms-xl'
        : 'h-4xl min-w-4xl py-sm border-lightest border-[3px] ms-md'"
    >
      <span>{{ cart?.items?.length ?? 0 }}</span>
    </UBadge>

    <UIcon
      name="i-ic-outline-shopping-cart"
      :class="`text-blue hover:text-blue-700 size-${isMobile ? 8 : 6} absolute bottom-0 left-0`"
    />
  </NuxtLinkLocale>
</template>

<style scoped></style>
