<script lang="ts" setup>
import type { ImageLinkRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ logos: ImageLinkRecord[] }>();

const localePath = useLocalePath();

const logoList = computed(() => props.logos.map((l) => {
  return {
    id: l.id,
    image: l.image,
    linkItem: l.optLinkItem,
    link: getLink(l.optLinkItem, localePath),
  };
}));
</script>

<template>
  <div class="max-w-1/4 grid grid-cols-2 items-center gap-xl">
    <div v-for="logo in logoList" :key="logo.id" class="relative">
      <div class="relative h-[200px]">
        <img :src="logo.image.responsiveImage.src" alt="" class="mx-auto max-h-full object-center">
      </div>

      <NuxtLinkLocale
        v-if="logo.linkItem"
        :to="logo.link.href" :target="logo.link.target"
        class="after:absolute after:inset-0"
      />
    </div>
  </div>
</template>

<style scoped></style>
