<script lang="ts" setup>
import type { LocaleObject } from "@nuxtjs/i18n";
import type { DropdownItem } from "#ui/types";

defineProps<{ isMobile?: boolean }>();
const { locale: currentLocale, locales } = useI18n();
const router = useRouter();

const availableLocales = computed(() => [locales.value
  .map((locale: LocaleObject) => {
    return {
      label: locale.name || locale.code,
      click: () => {
        localeSelected(locale.code!);
      },
      class: locale.code === currentLocale.value ? "underline [&>a]:text-blue" : "",
    } satisfies DropdownItem;
  })]);

function localeSelected(localeCode: string) {
  // Force to homepage
  router.push(`/${localeCode}`);

  // ALTERNATIVE
  // // const switchLocalePath = useSwitchLocalePath();
  // // router.push(switchLocalePath(localeCode));
}
</script>

<template>
  <UDropdown
    mode="click"
    class="z-30"
    :items="availableLocales"
    :ui="{ base: 'sm:!mt-md', width: 'w-fit' }"
    :popper="{ placement: 'bottom-end' }"
  >
    <div class="flex items-center gap-xs">
      <span class="uppercase">{{ currentLocale }}</span>
      <UIcon v-if="!isMobile" name="i-ic-round-keyboard-arrow-down" class="size-6" />
    </div>

    <template #item="{ item }">
      <NuxtLink
        class="w-full truncate p-1.5 text-left text-[1rem] font-medium text-blue hover:text-blue-700"
        @click="item.click()"
      >
        {{ item.label }}
      </NuxtLink>
    </template>
  </UDropdown>
</template>

<style scoped></style>
