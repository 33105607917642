<script lang="ts" setup>
import type { LayoutModelMainNavigationField } from "~/graphql/datocms/generated";

defineProps<{ navItems: LayoutModelMainNavigationField[] }>();

const route = useRoute();

const isOpen = ref(false);

watch(() => route.path, () => isOpen.value = false);

const { resetCatalog } = await useCatalog();
const localePath = useLocalePath();

function getLink(navItem: LayoutModelMainNavigationField) {
  if (navItem.__typename === "CustomLinkRecord") {
    return navItem.optLinkUrl;
  }

  if (navItem.__typename === "PageLinkRecord") {
    return navItem.link.slug;
  }

  return `#unknown-link-type`;
}

async function checkLink(navItem: LayoutModelMainNavigationField) {
  const link = localePath(getLink(navItem)!);
  if (route.meta.catalogSearchType && route.path === link) {
    isOpen.value = false;
    resetCatalog();
    return;
  }

  await navigateTo(link);
}
</script>

<template>
  <UIcon name="i-ic-round-menu" class="size-9 cursor-pointer" @click="isOpen = true" />

  <USlideover v-model="isOpen" side="left">
    <div class="h-6xl bg-header-top text-white">
      <UContainer class="flex h-full items-center justify-between font-bold">
        <span>{{ $t("header.title.menu") }}</span>
        <UIcon name="i-ic-round-close" class="size-6 cursor-pointer" @click="isOpen = false" />
      </UContainer>
    </div>

    <UContainer>
      <nav>
        <ul>
          <li v-for="navItem in navItems" :key="navItem.id">
            <NuxtLinkLocale
              :to="getLink(navItem)"
              class="no-link block py-lg text-lg font-bold text-darkest focus:outline-none"
              @click.prevent="checkLink(navItem)"
            >
              {{ navItem.text }}
            </NuxtLinkLocale>

            <hr class="border-light">
          </li>
        </ul>
      </nav>
    </UContainer>
  </USlideover>
</template>

<style scoped></style>
