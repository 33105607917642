<script lang="ts" setup>
const currentCartId = await useCartId();
</script>

<template>
  <div class="bg-header-top">
    <UContainer class="flex h-5xl items-center">
      <div class="ms-auto flex w-fit items-center gap-3xl text-sm font-bold text-white">
        
        <HeaderStandardAccountControls />
        <HeaderLocaleSwitcher />
      </div>
    </UContainer>
  </div>
</template>

<style scoped></style>
