<script lang="ts" setup>
const { loggedIn, user, clear } = useUserSession();
const { locale, t } = useI18n();

const accountItems: any[] = [];

if (loggedIn.value === false) {
  accountItems.push([{
    label: t("auth.label.sign-in"),
    icon: "i-ic-round-log-in",
    to: `/${locale.value}/auth/login`,
  }, {
    label: t("auth.label.create-account"),
    icon: "i-ic-outline-person-add-alt",
    to: `/${locale.value}/auth/register`,
  }]);
}
else if (loggedIn.value) {
  accountItems.push([{
    label: user.value?.name ?? user.value?.email ?? "[User]",
    slot: "account",
    disabled: true,
  }]);

  accountItems.push([{
    label: t("auth.label.sign-out"),
    icon: "i-ic-round-log-out",
    click: async () => {
      await clear();
      await navigateTo("/", { external: true });
    },
  }]);
}
</script>

<template>
  <UDropdown
    :items="accountItems"
    mode="click"
    class="z-30"
    :ui="{ item: { disabled: '' }, width: 'w-fit' }"
    :popper="{ placement: 'bottom-start' }"
  >
    <UIcon name="i-ic-round-person-outline" class="size-8" />

    <template #account="{ item }">
      <div class="cursor-default truncate text-left text-[1rem] text-blue hover:text-blue-700">
        {{ $t("auth.label.signed-in-as") }}
        <span class="font-medium">
          {{ item.label }}
        </span>
      </div>
    </template>

    <template #item="{ item }">
      <UIcon v-if="item.icon" :name="item.icon" class="size-4 shrink-0 text-blue hover:text-blue-700" />
      <span class="truncate text-[1rem] font-medium text-blue hover:text-blue-700">{{ item.label }}</span>
    </template>
  </UDropdown>
</template>

<style scoped></style>
