<script lang="ts" setup>
const data = await useLayout();
</script>

<template>
  <footer v-if="data" class="bg-footer font-light text-white">
    <UContainer class="flex flex-col gap-8xl py-5xl">
      <div class="flex h-fit flex-wrap gap-y-4xl sm:gap-y-7xl">
        <FooterLinkColumn v-for="linkColumn in data.linkColumn" :key="linkColumn.optTitle!" :data="linkColumn" />
        <FooterLogoList :logos="data.logoItems" />
      </div>
      <FooterBottom :data="data" class="pt-4xl" />
    </UContainer>

    
  </footer>
</template>

<style scoped></style>
