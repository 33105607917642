<script lang="ts" setup>
import type { LinkColumnModelLinkItemsField } from "~/graphql/datocms/generated";

defineProps<{ link: LinkColumnModelLinkItemsField }>();

const localePath = useLocalePath();

function getLinkUrl(link: LinkColumnModelLinkItemsField) {
  if (link.__typename === "CustomLinkRecord") {
    return link.optLinkUrl ?? "";
  }

  if (link.__typename === "PageLinkRecord") {
    return localePath(link.link.slug);
  }

  return `#unknown-link-type`;
}
</script>

<template>
  <li>
    <ULink :to="getLinkUrl(link)" :target="getTarget(getLinkUrl(link))" class="white-link">
      {{ link.text }}
    </ULink>
  </li>
</template>

<style scoped></style>
